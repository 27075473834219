import { isEmpty } from 'lodash-es';
import dayjs from 'dayjs';
import { ref, watch, onMounted, useI18n, useFlightSearchStore, useTracker, useConfigStore } from '#imports';

import { FlightSearchType, FlightSearchParams, FlightSearchHistory, FlightCabinType } from '#types/flight';

export const useFlightSearchForm = (options?: {
  initialSearchParams?: FlightSearchHistory;
  submitOnError?: boolean;
  showInitialSearchParams?: boolean;
}) => {
  const { initialSearchParams, submitOnError = false, showInitialSearchParams = true } = options ?? {};

  const { t } = useI18n();
  const flightSearchStore = useFlightSearchStore();
  const { jitsuEvent } = useTracker();
  const config = useConfigStore();
  const appConfig = useAppConfig();

  const searchType = ref<FlightSearchType>(
    !appConfig.defaults.flight.search.onewayCountries.includes(config.country!) ? 'roundtrip' : 'oneway',
  );
  const cabinType = ref<FlightCabinType>(FlightCabinType.ECONOMY);
  const depAirport = ref<FlightSearchHistory['depAirportProperties']>();
  const arrAirport = ref<FlightSearchHistory['arrAirportProperties']>();
  const travelDates = ref<string[]>(
    searchType.value === 'oneway'
      ? [dayjs().add(1, 'day').format('YYYY-MM-DD')]
      : [dayjs().add(1, 'day').format('YYYY-MM-DD'), dayjs().add(2, 'day').format('YYYY-MM-DD')],
  );
  const travelers = ref<Pick<FlightSearchParams, 'adult' | 'child' | 'infant'>>({ adult: 1, child: 0, infant: 0 });
  const errors = ref<{ [key: string]: string }>({});

  const maxDate = dayjs().add(480, 'day');

  watch(
    [depAirport, arrAirport, travelDates, searchType],
    ([depAirport, arrAirport, travelDates, searchType]) => {
      if (depAirport) {
        delete errors.value.depAirport;
      }

      if (arrAirport) {
        delete errors.value.arrAirport;
      }

      if (travelDates[0]) {
        delete errors.value.depDate;
      }

      if (travelDates[1]) {
        delete errors.value.retDate;
      }

      if (searchType === 'roundtrip' && !travelDates[1]) {
        travelDates.push(dayjs(travelDates[0]).add(1, 'day').format('YYYY-MM-DD'));
      }

      if (searchType === 'oneway' && travelDates[1]) {
        travelDates.pop();
      }
    },
    { deep: true },
  );

  onMounted(() => {
    const searchParams = initialSearchParams ?? flightSearchStore.searchHistory[0];

    if (searchParams && showInitialSearchParams) {
      searchType.value = searchParams.retDate ? 'roundtrip' : 'oneway';
      depAirport.value = searchParams.depAirportProperties;
      arrAirport.value = searchParams.arrAirportProperties;
      travelDates.value = [searchParams.depDate ?? dayjs().add(1, 'day').format('YYYY-MM-DD')];
      travelers.value = {
        adult: searchParams.adult ?? 1,
        child: searchParams.child ?? 0,
        infant: searchParams.infant ?? 0,
      };

      if (searchParams.cabinType) {
        cabinType.value = searchParams.cabinType;
      }

      if (searchType.value === 'roundtrip') {
        travelDates.value.push(searchParams.retDate ?? dayjs(travelDates.value[0]).add(1, 'day').format('YYYY-MM-DD'));
      }
    }
  });

  function setSearchType(type: FlightSearchType) {
    searchType.value = type;

    if (type === 'oneway' && travelDates.value.length > 1) {
      travelDates.value = [travelDates.value[0]!];
    }

    const eventName =
      type === 'oneway' ? 'flight-searchform-routetype-oneway' : 'flight-searchform-routetype-roundtrip';

    jitsuEvent('user-switch-button', {
      event_name: eventName,
      product: 'flight',
      object_name: 'search-form',
      object_parameter: 'triptype',
      trip_type: type,
    });
  }

  function switchAirport() {
    const temp = depAirport.value;
    depAirport.value = arrAirport.value;
    arrAirport.value = temp;

    jitsuEvent('user-click-button', {
      event_name: 'flight-autocomplete-select-switchautocomplete',
      product: 'flight',
      object_name: 'auto-complete-search',
      object_parameter: 'swap-airport',
    });
  }

  function handleSubmit(onSubmit?: (data: FlightSearchParams | null, errors?: { [key: string]: string }) => void) {
    if (!depAirport.value) {
      errors.value.depAirport = t('search.rqfrom');
    }

    if (!arrAirport.value) {
      errors.value.arrAirport = t('search.rqto');
    }

    if (!travelDates.value[0]) {
      errors.value.depDate = t('search.rqdeparturedate');
    }

    if (searchType.value === 'roundtrip' && !travelDates.value[1]) {
      errors.value.retDate = t('search.rqreturndate');
    }

    if (
      depAirport.value &&
      arrAirport.value &&
      depAirport.value.code === arrAirport.value.code &&
      !errors.value.arrAirport
    ) {
      errors.value.arrAirport = t('search.wffromto');
    }

    if (!isEmpty(errors.value)) {
      if (submitOnError) {
        onSubmit?.(null, errors.value);
      }

      return;
    }

    const data: FlightSearchParams = {
      depAirport: depAirport.value!.code,
      arrAirport: arrAirport.value!.code,
      depDate: travelDates.value[0]!,
      adult: travelers.value.adult,
      child: travelers.value.child,
      infant: travelers.value.infant,
      cabin: cabinType.value,
    };

    if (searchType.value === 'roundtrip') {
      data.retDate = travelDates.value[1];
    }

    flightSearchStore.addSearchHistory({
      ...data,
      depAirportProperties: depAirport.value,
      arrAirportProperties: arrAirport.value,
      searchType: searchType.value,
    });

    jitsuEvent('user-click-button', {
      event_name: 'search',
      product: 'flight',
      object_name: 'search-form',
      object_parameter: 'search',
      trip_type: searchType.value,
      dept_airport: data.depAirport,
      arr_airport: data.arrAirport,
      dept_date: data.depDate,
      return_date: data.retDate ?? '0000-00-00 00:00:00',
      traveler_count: data.adult + data.child + data.infant,
      adult: data.adult,
      child: data.child,
      infant: data.infant,
    });

    onSubmit?.(data);
  }

  return {
    searchTypes: ['oneway', 'roundtrip'] as FlightSearchType[],
    searchType,
    cabinTypes: ['economy', 'ecopremium', 'business', 'first'] as FlightCabinType[],
    cabinType,
    depAirport,
    arrAirport,
    travelDates,
    travelers,
    setSearchType,
    switchAirport,
    handleSubmit,
    errors,
    maxDate,
  };
};
